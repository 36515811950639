import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import styles from '../sass/components/radio.module.scss';
import getString from '../utils/get-string';

const Radio = ({ value, name = 'radio', onChange, checked, strings }) => (
  <label className={styles.radio}>
    <input
      type="radio"
      className={styles.radio__input}
      name={name}
      required
      onChange={event => onChange(event)}
      value={value}
      checked={checked}
    />
    <span className={styles.radio__caption}>{value}</span>
    <p className="validation">{getString(strings, "11220910")}</p>
  </label>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220910"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Radio {...data} {...props} />}
  />
)
