import { navigate, graphql, StaticQuery } from 'gatsby';
import React from 'react';

import styles from '../sass/components/quiz.module.scss';
import SvgArrowLeft from '../svg/arrowLeft';
import { createOrUpdateUserData, getUserData } from '../utils/userData';
import { Content, ContentDesc, Footer, FooterLinkBack, FooterLinkNext, FooterSubmit, Header, Page } from './page';
import Radio from './radio';
import getString from '../utils/get-string';

class Quiz extends React.Component {
  state = { value: false, error: null }

  handleChange(event) {
    const quizValue = event.target.value
    this.setState({ value: quizValue })
  }

  handleSubmit(event) {
    event.preventDefault()

    //if not logged redirect to login
    if (!getUserData())
      return navigate(`/login`, {
        state: { loginText: getString(this.props.strings, "11249232") },
      })

    const rightAnswer = this.props.quiz[
      this.props.quizIndex
    ].possibleAnswers.filter(({ isRight }) => isRight)[0].text

    const moveToNextQuestion = () => {
      if (rightAnswer !== this.state.value) return quizSolution()
      navigate(`/${this.props.lessonUrl}/quiz/${this.props.quizIndex + 2}`)
    }

    const isThisTheLastQuestion =
      this.props.quiz.length === this.props.quizIndex + 1

    const quizSolution = () => {
      createOrUpdateUserData({
        lessons: [
          ...getUserData().lessons.filter(
            lesson => lesson.id !== this.props.quizId
          ),
          {
            id: this.props.quizId,
            learned: rightAnswer === this.state.value,
          },
        ],
      })

      const correntAnsweredLessons = getUserData().lessons.filter(
        lesson => lesson.learned
      )

      this.updateUsersLessonsInDatoCMS()

      this.props.totalCount === correntAnsweredLessons.length
        ? navigate(`/final`, {
            state: { context: getString(this.props.strings, "11257336") },
          })
        : navigate(`/${this.props.lessonUrl}/answer`, {
            state: {
              answerIsRight: rightAnswer === this.state.value,
              backLink:
                rightAnswer !== this.state.value &&
                `/${this.props.lessonUrl}/quiz/${
                  this.props.quizIndex > 0 ? this.props.quizIndex + 1 : ''
                }`,
            },
          })
    }

    return isThisTheLastQuestion ? quizSolution() : moveToNextQuestion()
  }

  updateUsersLessonsInDatoCMS = () => {
    fetch(`https://site-api.datocms.com/items/${getUserData().userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Version': 2,
        Accept: 'application/json',
        Authorization: `Bearer 186716fd2040d981a07945e7a0f0de`,
      },
      body: JSON.stringify({
        data: {
          type: 'item',
          id: '47741',
          attributes: {
            lessons: JSON.stringify(getUserData().lessons),
          },
        },
      }),
    })
      .then(response => {
        if (this._isMounted) this.setState({ responseOk: response.ok })
        return response
      })
      .then(res => res.json())
      .then(data => {
        if (this._isMounted) {
          return this.state.responseOk
            ? console.log('item successfully updated')
            : console.log('error')
        }
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true

    const answersExists = () => {
      if (this.props.quiz[this.props.quizIndex].possibleAnswers.length < 1)
        this.setState({
          error:
            'No answers are set, please contact administrator to fix this.',
        })

      return this.props.quiz[this.props.quizIndex].possibleAnswers.length > 0
        ? true
        : false
    }

    const checkIfRightAnswerIsSet = () => {
      const rightAnswer =
        this.props.quiz[this.props.quizIndex].possibleAnswers &&
        this.props.quiz[this.props.quizIndex].possibleAnswers.filter(
          ({ isRight }) => isRight
        )
      if (!rightAnswer.length)
        this.setState({
          error:
            'No right answer is set, please contact administrator to fix this.',
        })
    }

    if (answersExists()) checkIfRightAnswerIsSet()
  }

  render() {
    const { lessonNumber, lessonUrl, quiz } = this.props
    const isThisTheLastQuestion = quiz.length === this.props.quizIndex + 1

    if (this.state.error) {
      return (
        <Page type="error">
          <Content>
            <div className={styles.quiz__error}>
              <p className="h5">{this.state.error}</p>
              <FooterLinkNext to={`/lessons`}>
                <SvgArrowLeft />
                {getString(this.props.strings, "11220979")}
              </FooterLinkNext>
            </div>
          </Content>
        </Page>
      )
    }

    return (
      <form
        className={styles.quiz}
        onSubmit={event => this.handleSubmit(event)}
      >
        <Page type="white">
          <Header closeUrl={`/lessons#${lessonUrl}`}>{lessonNumber}</Header>
          <Content>
            <ContentDesc>{lessonNumber} I {getString(this.props.strings, "11220985")}</ContentDesc>
            <h1 className={styles.quiz__title}>
              {quiz[this.props.quizIndex].question}
            </h1>
            {quiz[this.props.quizIndex].possibleAnswers &&
              quiz[this.props.quizIndex].possibleAnswers.map((q, key) => (
                <Radio
                  key={key}
                  onChange={event => this.handleChange(event)}
                  value={q.text}
                  checked={q.text === this.state.value}
                />
              ))}
              <div className={styles.quiz__bottomgap}></div>
          </Content>
          <Footer>
            <FooterSubmit disabled={!this.state.value ? true : false}>
              {isThisTheLastQuestion ? getString(this.props.strings, "11220981") : getString(this.props.strings, "11257451")}
            </FooterSubmit>
            {this.props.quizIndex > 0 ? (
              <FooterLinkBack
                onClick={() =>
                  navigate(
                    `/${this.props.lessonUrl}/quiz/${
                      this.props.quizIndex - 1 === 0
                        ? ''
                        : this.props.quizIndex + 2
                    }`
                  )
                }
              >
                <SvgArrowLeft />
                {getString(this.props.strings, "11220987")}
              </FooterLinkBack>
            ) : (
              <FooterLinkBack to={`/${lessonUrl}`}>
                <SvgArrowLeft />
                {getString(this.props.strings, "11220983")}
              </FooterLinkBack>
            )}
          </Footer>
        </Page>
      </form>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11249232", "11257336", "11220979", "11220985", "11220981", "11257451", "11220987", "11220983"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Quiz {...data} {...props} />}
  />
)
